<template>
    <div class="age">
        <loader :height="200" v-if="!hasLoaded"/>
        <div v-else class="chart"></div>
    </div>
</template>

<script>
  import * as HighCharts from 'highcharts'

  export default {
    props: ['value'],
    data () {
      return {
        chart: null,
        categories: [],
        series: [],
      }
    },

    computed: {
      ages () {
        return this.value
      },
      hasLoaded () {
        return !!this.ages
      }
    },

    watch: {
      hasLoaded () {
        this.init()
      }
    },

    mounted () {
      this.init()
    },

    methods: {
      init () {

        if (!this.hasLoaded) {
          return
        }

        this.series = []

        if (this.chart) {
          this.chart.destroy()
        }

        setTimeout(this.load, 100)
      },
      load () {
        if (this.ages) {
          const sortedRows = [...this.ages.rows]

          let serie = {
            name: $t('visitors.audience.numberOfVisits'),
            color: $org('colors.standard.secondary.hex'),
            data: []
          }

          sortedRows.sort((a, b) => {
            return a.userAgeBracket > b.userAgeBracket ? 1 : -1
          }).forEach(row => {
            this.categories.push(row.userAgeBracket)
            serie.data.push(row.sessions)
          })

          this.series = [serie]

          this.render()
        }
      },

      render () {
        let element = this.$el.querySelector('.chart')

        this.chart = new HighCharts.chart(element, {
          chart: {
            type: 'bar'
          },
          colors: [
            '#7cb5ec', '#434348', '#00C69E', '#FE9D45', '#8085e9',
            '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'
          ],
          title: false,
          xAxis: {
            categories: this.categories,
          },
          yAxis: {
            min: 0,
            title: {
              text: false,
              align: 'high'
            },
            labels: false,
            gridLineWidth: 0,
          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: false
              }
            }
          },
          legend: false,
          credits: {
            enabled: false
          },
          series: this.series
        })
      }
    }
  }
</script>
