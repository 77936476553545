<template>
    <div class="userType">
        <loader v-if="!hasLoaded" :height="200"/>
        <div v-else class="chart"></div>
    </div>
</template>

<script>

  import * as Highcharts from 'highcharts'

  export default {
    props: ['value'],
    data () {
      return {
        chart: null,
        series: [],
      }
    },
    computed: {
      types () {
        return this.value
      },

      hasLoaded () {
        return !!this.types
      },
    },

    watch: {
      hasLoaded () {
        this.init()
      }
    },

    mounted () {
      this.init()
    },

    methods: {
      init () {
        if (!this.hasLoaded) {
          return
        }

        this.series = []

        if (this.chart) {
          this.chart.destroy()
        }

        setTimeout(this.load, 150)
      },
      load () {
        if (this.hasLoaded) {

          this.$emit('hasData')

          let serie = {
            name: $t('visitors.audience.visitors'),
            colorByPoint: true,
            data: []
          }

          const rows = [...this.types.rows]

          rows.forEach(row => {
            if (row.userType === 'New Visitor' || row.userType === 'new') {
              serie.data.push({
                name: $t('visitors.audience.new'),
                y: row.sessions
              })
            }

            if (row.userType === 'Returning Visitor' || row.userType === 'returning') {
              serie.data.push({
                name: $t('visitors.audience.returning'),
                y: row.sessions
              })
            }
          })

          this.series = [serie]
          this.render()
        }
      },

      render () {
        let element = this.$el.querySelector('.chart')

        this.chart = new Highcharts.chart(element, {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          colors: [
            $org('colors.standard.secondary.hex'), '#434348'
          ],
          title: false,
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>',
                style: {
                  'color': '#9A9A9A',
                  'fontSize': '12px',
                  'fontWeight': 'normal',
                  'textOutline': 'none',
                  'fontFamily': 'Roboto',
                }
              }
            }
          },
          credits: false,
          series: this.series
        })
      }
    }
  }
</script>
