<template>
    <div class="gender">
        <div class="male text-center">
            <img src="@/assets/images/analytics/audience/male.png" :style="{height: maleHeight + 'px'}"/>
            <div class="text-muted">
                <span v-if="!hasLoaded" class="mock">0%</span>
                <span v-else>{{malePercent | percent}}</span>
            </div>
        </div>

        <div class="female text-center">
            <img src="@/assets/images/analytics/audience/female.png" :style="{height: femaleHeight + 'px'}"/>
            <div class="text-muted">
                <span v-if="!hasLoaded" class="mock">0%</span>
                <span v-else>{{femalePercent | percent}}</span>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: ['value'],
    data () {
      return {
        maxHeight: 100
      }
    },

    computed: {
      genders (){
        return this.value
      },

      hasLoaded () {
        return !!this.genders
      },

      total () {
        if (!this.hasLoaded) return 0
        return this.genders.total
      },

      male () {
        if (!this.hasLoaded) return 0
        return this.getSessions('male')
      },

      female () {
        if (!this.hasLoaded) return 0
        return this.getSessions('female')
      },

      malePercent () {
        if (this.total === 0) {
          return 0
        }

        return this.male / this.total
      },

      femalePercent () {
        if (this.total === 0) {
          return 0
        }

        return this.female / this.total
      },

      maleHeight () {
        const mostMale = ((this.malePercent - 0.5) > 0)

        if (mostMale) {
          return this.maxHeight
        }

        return (this.maxHeight / this.female) * this.male
      },

      femaleHeight () {
        const mostFemale = ((this.femalePercent - 0.5) > 0)

        if (mostFemale) {
          return this.maxHeight
        }

        return (this.maxHeight / this.male) * this.female
      },
    },

    methods: {
      getSessions (filter) {
        return this.genders.rows.filter(row => {
          return row.userGender === filter
        })[0]['sessions']
      }
    }
  }
</script>
