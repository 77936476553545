<template>
    <div class="row" v-if="show">
        <div class="col-md-12">
            <div class="audience-row">
                <card icon="heart"
                      :headline="$t('visitors.audience.headline')"
                      :description="$t('visitors.audience.description')">
                    <div class="audience row">

                        <div class="col-md-4 border-right">
                            <strong>{{$t('visitors.audience.gender')}}</strong>
                            <gender v-model="userGender"/>
                        </div>

                        <hr class="visible-sm visible-xs">

                        <div class="col-md-4 border-right" v-if="userAge">
                            <strong>{{$t('visitors.audience.age')}}</strong>
                            <age v-model="userAge"/>
                        </div>

                        <hr class="visible-sm visible-xs">

                        <div class="col-md-4" v-if="userTypes">
                            <strong>{{$t('visitors.audience.userType')}}</strong>
                            <user-type v-model="userTypes"/>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .audience {
        padding-top: 15px;

        .border-right {
            border-right: 1px solid #dedede;
        }

        strong {
            margin: 0 0 5px;
            display: block;
        }


        .age {
            .loader {
                position: absolute;
                top: calc(50% - 5px);
                width: 100%;
            }

            .chart {
                height: 200px;
                width: 100%;
            }
        }

        .gender {
            width: 200px;
            margin: 20px auto;

            img {
                height: 100px;
                transition: all .5s ease;
            }

            .female,
            .male {
                width: calc(50% - 20px);
                display: inline-block;
                margin: 0 5px;

                & > div {
                    border-top: 1px solid #dedede;
                    margin-top: 15px;
                    padding: 15px 15px 0;
                }
            }

            .chart tspan {
                font-weight: normal !important;
            }
        }

        .userType {
            .loader {
                position: absolute;
                top: calc(50% - 5px);
                width: 100%;
            }

            .chart {
                height: 200px;
                width: 100%;
            }
        }
    }
</style>

<script>
  import UserType from '@/app/website/components/audience/UserType'
  import Gender from '@/app/website/components/audience/Gender'
  import Age from '@/app/website/components/audience/Age'
  import { mapGetters } from 'vuex'

  export default {

    computed: {
      ...mapGetters('website', {
        userGender: 'getUserGender',
        userTypes: 'getUserType',
        userAge: 'getUserAge'
      }),

      show () {
        return this.loadedAllChildren
      },

      loading () {
        return !this.loadedAllChildren
      },

      loadedAllChildren () {
        return !!this.userGender && !!this.userTypes && !!this.userAge
      }
    },

    components: {
      Gender,
      Age,
      UserType
    }
  }
</script>
